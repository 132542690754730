
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .purchase-info {
        position: relative;
        &::after {
            // content: "";
            display: block;
            position: absolute;
            width: 1px;
            height: calc(100% - 16px);
            top: 50%;
            right: -8px;
            transform: translateY(-50%);
            background-color: var(--border-color-light);
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
