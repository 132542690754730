
.mypage-layout-row {
    --grid-gutter-mypage: 10px;
    @media (min-width: 1400px) {
        --grid-gutter-mypage: 14px;
    }
    @media (min-width: 1600px) {
        --grid-gutter-mypage: 14px;
    }
    @media (min-width: 1700px) {
        --grid-gutter-mypage: 20px;
    }
    @media (min-width: 1800px) {
        --grid-gutter-mypage: 30px;
    }
    margin: calc(var(--grid-gutter-mypage) * -1) !important;
    > [class*="col"] {
        padding: var(--grid-gutter-mypage) !important;
    }

    &__aside,
    &__contents {
        flex: 0 0 100%;
        max-width: 100%;
    }
    &__aside-inner {
        width: 100%;
        margin-bottom: 20px;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .mypage-layout-row {
        &__aside {
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
        }
        &__aside-inner {
            width: 240px;
            margin-bottom: 0;
        }
        &__contents {
            flex-basis: 0;
            flex-grow: 1;
            max-width: calc(100% - 268px);
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    // .mypage-layout-row {
    //     &__aside,
    //     &__contents {
    //         flex: 0 0 100%;
    //         max-width: 100%;
    //     }
    //     &__aside-inner {
    //         width: 100%;
    //         margin-bottom: 20px;
    //     }
    // }
}
@media (min-width: 1400px) {
    .mypage-layout-row {
        &__aside {
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
        }
        &__aside-inner {
            width: 240px;
            margin-bottom: 0;
        }
        &__contents {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }
    }
}
