
.cart-foot-row {
    &__arithmetic {
        display: none;
    }
}
@media (min-width: 1200px) {
    .cart-foot-row {
        justify-content: center !important;
        margin: -20px -2px;
        &__calculation,
        &__total {
            padding: 20px 2px;
        }
        &__divider {
            display: none;
        }
        &__arithmetic {
            display: block;
        }
    }
}
